import Interpolate from '@engined/client/components/Interpolate.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { SERVER_ERROR_SNACKBAR_CONTENT, SERVER_ERROR_SNACKBAR_TITLE } from '@engined/client/locales.js';
import { Typography } from '@mui/material';
import React from 'react';

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const ServerErrorSnackbar: React.FunctionComponent<Props> = ({ className }) => {
  const { t } = useLocale();
  return (
    <div className={className}>
      <Typography variant="h6" component="h6">
        {t(SERVER_ERROR_SNACKBAR_TITLE)}
      </Typography>
      <Interpolate resource={SERVER_ERROR_SNACKBAR_CONTENT} />
    </div>
  );
};

ServerErrorSnackbar.displayName = 'ServerErrorSnackbar';

export default React.memo<Props>(ServerErrorSnackbar);
