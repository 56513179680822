import { ApolloError } from '@apollo/client/index.js';
import { SafeHTML } from '@engined/client/components/Interpolate.js';
import BadUserInputSnackbar from '@engined/client/components/snackbars/BadUserInputSnackbar.js';
import ClientErrorSnackbar from '@engined/client/components/snackbars/ClientErrorSnackbar.js';
import ServerErrorSnackbar from '@engined/client/components/snackbars/ServerErrorSnackbar.js';
import { Logger } from '@engined/core/services/logger.js';
import { GraphQLError } from 'graphql';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import React from 'react';

export function displayError(
  err: ApolloError | Error,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  logger: Logger,
): SnackbarKey | null {
  let key: SnackbarKey | null = null;
  if ('graphQLErrors' in err && err?.graphQLErrors?.length) {
    const graphQLErrors = err.graphQLErrors;
    const badUserInputErrors = getBadUserInputErrors(graphQLErrors);
    const notFoundErrors = getNotFoundErrors(graphQLErrors);
    const internalServerErrors = getInternalServerErrors(graphQLErrors);

    if (badUserInputErrors.length) {
      // Hide toasts on server
      const errorMessage =
        badUserInputErrors.length === 1 ? (
          <SafeHTML message={badUserInputErrors[0]} />
        ) : (
          <ul>
            {badUserInputErrors.map((message, index) => (
              <li key={index}>
                <SafeHTML message={message} />
              </li>
            ))}
          </ul>
        );

      key = enqueueSnackbar(<BadUserInputSnackbar error={errorMessage} showTitle={badUserInputErrors.length > 1} />, {
        variant: 'error',
      });
    }

    if (notFoundErrors.length) {
      // Hide toasts on server
      const errorMessage =
        notFoundErrors.length === 1 ? (
          <SafeHTML message={notFoundErrors[0]} />
        ) : (
          <ul>
            {notFoundErrors.map((message, index) => (
              <li key={index}>
                <SafeHTML message={message} />
              </li>
            ))}
          </ul>
        );

      key = enqueueSnackbar(<BadUserInputSnackbar error={errorMessage} showTitle={badUserInputErrors.length > 1} />, {
        variant: 'error',
      });
    }

    if (internalServerErrors.length) {
      key = enqueueSnackbar(<ServerErrorSnackbar />, {
        variant: 'error',
      });
    }
  } else if (
    'networkError' in err &&
    err.networkError &&
    'statusCode' in err.networkError &&
    err.networkError.statusCode === 500
  ) {
    key = enqueueSnackbar(<ServerErrorSnackbar />, {
      variant: 'error',
    });
  } else {
    key = enqueueSnackbar(<ClientErrorSnackbar />, {
      variant: 'error',
    });
  }

  logUnhandledErrors(err, logger);

  return key;
}

export function logUnhandledErrors(err: ApolloError | Error, logger: Logger): void {
  // No logger
  if (!logger) {
    return;
  }

  // No graphQLErrors
  if ('graphQLErrors' in err) {
    if (err.graphQLErrors?.length === undefined) {
      logger.error(err);
    }

    // Log only if not BAD_USER_INPUT
    const badUserInputErrors = getBadUserInputErrors(err.graphQLErrors);
    const notFoundErrors = getNotFoundErrors(err.graphQLErrors);
    const internalServerErrors = getInternalServerErrors(err.graphQLErrors);

    if (badUserInputErrors.length + notFoundErrors.length + internalServerErrors.length === 0) {
      logger.error(err);
    }
  } else {
    logger.error(err);
  }
}

function getErrorsByCode(
  graphQLErrors: readonly GraphQLError[],
  code: 'BAD_USER_INPUT' | 'INTERNAL_SERVER_ERROR' | 'NOT_FOUND',
): string[] {
  return graphQLErrors.filter((e) => e.extensions?.code === code).map((m) => m.message);
}

export function getBadUserInputErrors(graphQLErrors: readonly GraphQLError[]): string[] {
  return getErrorsByCode(graphQLErrors, 'BAD_USER_INPUT');
}

export function getNotFoundErrors(graphQLErrors: readonly GraphQLError[]): string[] {
  return getErrorsByCode(graphQLErrors, 'NOT_FOUND');
}

export function getInternalServerErrors(graphQLErrors: readonly GraphQLError[]): string[] {
  return getErrorsByCode(graphQLErrors, 'INTERNAL_SERVER_ERROR');
}
