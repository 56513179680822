import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { BAD_USER_INPUT_SNACKBAR_CONTENT, BAD_USER_INPUT_SNACKBAR_TITLE } from '@engined/client/locales.js';
import { styled, Typography } from '@mui/material';
import React from 'react';

interface OwnProps {
  error?: React.ReactNode;
  showTitle?: boolean;
}

type Props = OwnProps;

const Root = styled('div')(({ theme }) => ({
  '& a': {
    color: '#fff',
  },
}));

Root.displayName = 'Root';

const BadUserInputSnackbar: React.FunctionComponent<Props> = ({ error, showTitle = true }) => {
  const { t } = useLocale();
  return (
    <Root>
      {showTitle && (
        <Typography variant="h6" component="h6">
          {t(BAD_USER_INPUT_SNACKBAR_TITLE)}
        </Typography>
      )}
      {error || t(BAD_USER_INPUT_SNACKBAR_CONTENT)}
    </Root>
  );
};

BadUserInputSnackbar.displayName = 'BadUserInputSnackbar';

export default React.memo<Props>(BadUserInputSnackbar);
